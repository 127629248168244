
.AboutMe{
    display: block;
    height: auto;
    margin-bottom: 5%;

    p{
        font-size: 18px;
    }

    .highlightPra{
        //font-weight: bold;
        color: #d6dde6;
        background-color:  #48566B;
        padding: 0px 5px;
        border-radius: 2px;
    }

    .AboutMePragraph{
        text-align: left;
    }

}