.Data {
    width: 100%;
    text-align: center;
    border: 3px solid #48566B;
    box-sizing: border-box;
    margin: 4px 4px;
    box-shadow: 1px ;
    border-radius: 5px;
    box-shadow: 2px 1px 5px grey;

    -webkit-transform: perspective(.1px) translateZ(0);
    transform: perspective(.1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;

 
}

.Data:hover, .Data:focus, .Data:active {
    -webkit-transform: scale(1.025);
    transform: scale(1.025);
  }


   @keyframes slidein {

        from  {
            border:0;
        }
        to {
            border: 3px;
        }
    }

  
/* .Data:hover,
.Data:active {
    background-color: #C0DDF5;
} */

.Datas {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    position: relative;
    align-items: left;
    justify-content: left;
    
}

/* .Data:hover{
    background-color: #a4b5c4;
} */

.ProjectsMac{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    position: relative;
    align-items: center;
    justify-content: center;

}

a:link, a:visited {
    font-weight: 500;
    color: rgb(0, 0, 0);
    text-decoration: none;
}

a:hover{
    color: #384253;
}

/* Table {
    margin-left: auto;
    margin-right: auto;

} */

.DescriptionButton{
    /* border: black 1px solid; */
    font-weight: 500;
    cursor: pointer;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
}

.DescriptionButton:hover{
    color: #48566B;
    text-decoration: underline;
    
}

.Description{
    /* animation-duration: 0.5s;
    animation-name: slidein;
    animation-iteration-count:initial; */
/* 
    max-height: 50em;
    overflow:auto;
    transition: max-height 5.5s, overflow 0.5s 0.5s; */
}




.ProjectsMac span {
    padding:5px;
    color:#384253;
    border: 2.7px solid #48566B;
    border-radius: 4px;
    font-weight:500;
    margin: 3px;
}

.ProjectsMac span:nth-child(even),
.ProjectsMac span:nth-child(even):hover{
    background-color: #48566B;
    color:#cad0d8;
}

.pName{
    font-size: 20px;
    font-weight: bold;
    color:#48566B;
    margin: 10px;
}

.pType{
    font-weight:600;
    color:#3b475ab7;
    //text-decoration: 2px underline;
}

.Projects{
    display: block;
    height:auto;
    margin-bottom: 5%;
    /* margin: 300px 4%; */
}

.ProjectFilter{
    display: block;
    border: 50% 50%;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    //float: right;  
    margin-bottom: 2%;

    Button{
        text-decoration: none;
        display: inline-block;
        font-size: 17px;
        transition-duration: .2s;
        cursor: pointer;
        margin:1%;
        background-color: #c1c9d3; 
        border: 3px solid #48566B;
        //width: 150px;
        width: 100%;
        min-height: 50px;
        font-weight: bold;
        text-align: center;
        color: #48566B;
        //border-radius: 25px;
        border-radius: 5px;
        text-transform: capitalize;
    }
    Button:hover, .activeButton{
        background-color: #48566B;
        color:#c1c9d3; 
    }


    
}
option{
    font-weight: bold;
    font-size: 15px;
}


select:focus > option:checked { 
   
    color:#48566B;
}

.ProjectFilterOptions{
    border:3px solid #48566B;
    width: 155px;
    height: 40px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background-color: #c1c9d3;
}

select {
    text-align-last:center; 
}





.popUpDeatils{
    .btnShowPupUp{
        color:#48566B;
        font-weight: bold;
        font-size: 15px;
        // border: 3px solid #48566B;
        border-radius: 5px;
        background-color: #c1c9d3;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .upperInfo{
        text-align: center;
        font-weight: bold;
        padding: 10%;
        background-color: black;
    }
    h3{
        text-align: center;
    }


}

@media (max-width: 320px) {

    .ProjectFilter Button{
            font-size: 15px;
            min-width: calc(100vw - 60px) !important;
            max-width: calc(100vw - 60px) !important;
            //min-height: 50px;
            //border-radius: 25px;
        }
    
}