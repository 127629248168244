
.Qualification{

    min-height: 450px;
    margin-bottom:30px;


    .qualification__text{
        margin-top:-20px;
        
    }

    .qualification__data {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        column-gap: 1rem;
    }

    .qualification__rounder {
        display: inline-block;
        width: 13px;
        height: 13px;
        background-color: #48566B;;
        border-radius: 50%;
    }
    .qualification_degree{
        
    }

    .qualification__line {
        display: block;
        width: 2px;
        height: 100%;
        background-color: #48566B;
        transform: translate(5.5px, -7px);
        animation-duration: 2s;
        animation-name: slidein;
        animation-iteration-count: 1;
    }

    .qualification__line2 {
        display: block;
        width: 2px;
        height: 100%;
        background-color: #48566B;
        transform: translate(5.5px, -7px);
        animation-duration: 2s;
        animation-name: slidein2;
        animation-iteration-count: 1;
    }

    @keyframes slidein {

        from  {
            height:0%
        }
        to {
            height: 100%;
        }
    }

    @keyframes slidein2 {

        from  {
            height:0%
        }
        to {
            height: 100%;
        }
    }
    


    
    .btnColorSelected{
        color:#48566B;
        position: relative;
        display: inline-block;
    }

    .btnColorDefault{
        color:#3c3e3f;
        position: relative;
        display: inline-block;
        // text-decoration: line-through;
        
    }

    .btnColorSelected:before{
        position: absolute;
        height: 3px;
        width: 100%;
        left: 0%;
        bottom: 3px;
        background: #48566B;
        display: block;
        content: "";
        animation: cutTheText 2s;
        // animation-name: slidein2;
        // animation-iteration-count: 1;
    }

    @keyframes cutTheText {

        from  {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }
    


    
    .btn{
        cursor: pointer;
        font-size:20px;
        padding:6px;
        font-weight:bold;
        margin:1%;
        border:none;
        background-color: #c1c9d3;
        border-radius: 5%;
    }
    .btn:hover{
        background-color:#b9c1ca;
    }


    
    h3{
        color:#48566B;
    }

    .duration{
        color:#636a81;
        font-size:14px;
        margin-top: 1%;
        font-weight:bold;
    }




}

