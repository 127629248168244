.toggleSideBar{

    background-color: #48566B;
    //opacity: 1;
    color: #C7D3D4FF;
    font-size:22px;
    font-weight: bold;
    width: 280px;
    text-align:center;
    height: 100vh;
    position: fixed;
    top:0%;
    box-shadow: 5px 5px 10px grey;
}

.toggleSideBarOptions{
    top: 50%;
    margin-top: 130px;
}


.toggleOptions{
    display: block;
    border-bottom: 2px solid #C7D3D4FF;   
    height: 38px;
    margin: 25% 0px; 
    cursor: pointer;
    
}

.toggleOptions:hover{
    color: #c7d4d4;
}

// @media (min-width: 1141px) {
//     .toggleSideBar {
//         display: none;
//     }
// }

@media only screen and (max-width : 480px) {
    .toggleSideBar{
        width: 100%;
        opacity: 5.5;
    }

    .toggleOptions{
        margin: 20px 0px; 

    }
}