.DrawerToggle {
    width: 50px;
    height: 50px;
    display: flex;
    flex-flow: column;
    opacity: .96;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    background-color:#48566B;
    margin:5px 5px;
    position:fixed;
    top:0;
    border-radius: 5px;


    div {
    width: 80%;
    height: 50px;
    background-color: #c1c9d3;
    margin:4px;
    }


    // @media (min-width: 1141px) {
    //     .DrawerToggle {
    //         display: none;
    //     }
    // }


}