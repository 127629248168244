.icons{

    margin-top: 30px;

    a{
        color: #000000;
        text-decoration: none;
    }
    .fastPageAnimation{
        color: #3d3c3c;
        text-decoration: underline;
        animation-duration: 3s;
        animation-name: slideinFastPage;
        animation-iteration-count:initial;
    }
    
    @keyframes slideinFastPage {
        from  {
            margin-right: -800px;
            width: 150%;
        }
        to {
          margin-right: 0;
          width: 100%;
        }
    }
    a:link,a:visited,a:active {
        color:#3a4046;
    }
    .facebook:hover{
        color: #3b5998;
    }
    .instagram:hover{
        color: #c13584;
    }
    .linkedin:hover{
        color: #0077B5;
    }
    .github:hover{
        color: #16191b;
    }
    .phone:hover{
        color: #037e86;
    }
    .mail:hover{
        color: #b81b1b;
    }
    
}

