
.CvPart{
    // display: block;
     border: 1px solid #48566B;
     bottom: 0;
     //margin: -1300px 0px;
     width: 100%;
     border-radius: 5px 5px 0 0;
     background-color: #48566B;
     text-align: center;
 

 .CVH1{

    color: rgb(199, 199, 212);
    margin: 0 5px;
}

 .H1animation{
    animation: winggles 5s;
    animation-iteration-count:initial;

}

@keyframes  winggles{
    0% {transform: rotate(10deg);}
    25% {transform: rotate(-10deg);}
    50% {transform: rotate(20deg);}
    75% {transform: rotate(-5deg);}
    100% {transform: rotate(0deg);}
}

}