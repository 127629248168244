$width: calc(100vh - 100px);

.sideBar{

    opacity: .95;
    color: #c1c9d3;
    font-size:22px;
    font-weight: bold;
    width: 280px;
    text-align:center;
    height: 100vh;
    position: fixed;
    top: 50%;
    margin-top: -425px;
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count:initial;



.options{
    display: block;
    border-bottom: 2px solid #c1c9d3;   
    height: 38px;
    cursor: pointer;

  /* changed made */
    border-radius: 0px 0px 5px  0;
    padding:5px 5px 10px 5px;

    margin-top: 25%  ;
    border-bottom: 3px solid #48566B; 
    border-right: 3px solid #48566B; 
    color: #48566B;

     background: linear-gradient(to right,#48566B 50%,  #c1c9d3 50%);
     background-size: 200% 100%;
     background-position: right top;
     transition: all .28s ease-out;

}

.options:hover{

    color: #c1c9d3;  
    background-position: left top;
}


@media (max-height: 768px) {
    margin-top: -330px;
    .options{
        margin-top: 16%;
    }
}

@media (max-height: 720px) {
    margin-top: -320px;
    .options{
        margin-top: 14%;
    }
}



}