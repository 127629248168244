.Skills{
    display: block;
    margin-top: 0%;
    margin-bottom:5%;
   // margin: 100% 4% 0px;



.Grid{
   border:2px solid black;
   font-size: 1rem;
   color: white;
   text-transform: uppercase;
   letter-spacing: 1px;
   height: auto;
   bottom: 0;
   left: 0;
   margin-left: -30px;
   padding-bottom: 4%;
   // border-radius: 6px;
}

// .label{
//    border:2px solid black;
//    color: white;
//    text-transform: uppercase;
//    letter-spacing: 1px;
//    height: auto;
//    margin-left: -30px;
//    text-align: center;
//    margin: auto;

//    color: #000;
//    font-weight: bold;
//    font-size: 22px;

// }


.Datas {
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   width: 90%;
   margin: auto;
   position: relative;
   margin-top: 1%;
   
}

.DatasBottomLabel{
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   width: 50%;
   margin: auto;
   position: relative;
   font-size: 22px;
   font-weight: bold;
   margin-top: 5px;
   padding-top: 6px;
   border-top: 5px solid #000;
}



.icon{
   color: rgba(0, 0, 0, 0);
}

.Grid:hover{
  // background-color: #cccdcf;
}

ul,h3{
   color: #000;
}

ul{

   // margin-top: -3%;
}

// Button:hover{
//    border:2px solid #000;
// }




}