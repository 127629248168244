.fastPage{
    display: table-cell;
    text-align: right;
    vertical-align: middle;
    width:  200vh;
    font-size: 68px;
    font-weight: bold;
    height: 100vh;
    overflow: hidden;


.fastPageAnimation{
    color: #48566B;
    font-size: 20px;
    //text-decoration: underline;
    animation-duration: 3s;
    animation-name: slideinFastPage;
    animation-iteration-count:initial;
    
}


@keyframes slideinFastPage {
    from  {
        margin-right: -800px;
        //width: 0%;
    }
    to {
      margin-right: 0;
      //width: 100%;
    }
    }

.midTextFastPage{
    color: #48566B;
    font-size: 25px;
    background-color: #47597415;
    padding: 10px 20px;
    width: 20%;
    border-radius: 5px;;
    //text-decoration: underline;
}

@function get-vw($target) { 
    $vw-context: (1000*.01) * 1px;
    @return ($target/$vw-context) * 1vw;
  }

.TypeWritter{
    font-size: 100px;
    margin-top: 15px;
}

// #TypeWritter{
//     font-size: 300px;
// }



@media (max-width: 900px) {

    .TypeWritter{
        font-size: 80px;
    }

}

@media (max-width: 750px) {


    .midTextFastPage{
        font-size: 20px;
    }
    .TypeWritter{
        font-size: 60px;
    }
}

}