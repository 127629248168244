::-webkit-scrollbar {
  width: 15px;
}


/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
  */
/* Handle */
::-webkit-scrollbar-thumb {
  background: #48566B; 
  background-color: #48566B;
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #424e61; 

  
  /* border-bottom-style: dashed ; */
 
}


h1,h2{
  color:#424e61;
  text-align: center;
}

h1{
  font-size:40px;
}

/* h1{
  width: 250px;
  margin: auto;
  padding: 10px;
  background: linear-gradient(to right,#48566B 50%,  #c1c9d3 50%);
  background-size: 200% 100%;
  background-position: right top;
  transition: all .28s ease-out;
  border-radius: 5px;
}
h1:hover{
  color: #c1c9d3;  
  background-position: left top;
} */

body {
  background-color: #c1c9d3;
}


.AllInfo{
  display:block;
  position: relative;
  left: 26%;
  width:73%;


} 


.ScrollToTop{
    position: fixed;
    bottom: 0;
    right: 0;
    
}

@media (max-width: 1199px) {
  .AllInfo{
    left: 8%;
    width:87%;
  }
  
}

@media (max-width: 1200px) {
  .AllInfo{
    text-align: center;
    width:87%;
    left: 7%;
  }

  
  
}