
.ContactMe{
    
    

::placeholder {
    color: rgb(199, 199, 212);
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: rgb(199, 199, 212);
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
   color: rgb(199, 199, 212);
  }

  a{
    color: rgb(199, 199, 212);
  }

  .H1Secondary{
    color:rgb(199, 199, 212);
}

//   .ContactMe h1{
//     color:rgb(199, 199, 212);
// }

.ContactMeTextPart{
    color:rgb(199, 199, 212);
}

#ContactMeTextPart{
    color:rgb(199, 199, 212);
}
input,textarea{
    margin: 30px 1px;
    display: block;
    width: 50%;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    border: 3px rgb(199, 199, 212);
    background-color: #48566B;
    border-bottom-style: solid;
    text-align: center;
    font-size: 20px;
    outline: none;
    font-size: 20px;
    color: rgb(199, 199, 212);
    resize: vertical;
    caret-color:rgb(199, 199, 212);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0 100px #48566B inset !important;
    -webkit-text-fill-color: rgb(199, 199, 212) !important;
}
// input:-webkit-autofill {
//     background-color: #48566B !important;
//   }
//   input:autofill {
//     background-color: #48566B !important;
//   }


.bottomText{
    position: absolute;
    bottom: 1px;
    width: 100%;
    color: rgb(199, 199, 212);
    font-weight: bold;
    font-size:16px;

}

table{
    text-align: center;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
}


table td{
    width: 30%;
}


form{
    background-color: #48566B;
    width: 100%;
   // height: 500px;
    display:grid;
    place-items: center;
}

.ContactMeTrySuc{
    color:rgb(199, 199, 212);
    font-weight: bold;
    font-size: 25px;
}



.alartMsg{
    color: rgb(245, 181, 181);
    font-size: 18px;
    font-weight: bold;
}


   
.H1animation{
    animation: winggles 5s;
    animation-iteration-count:initial;

}

@keyframes  winggles{
    0% {transform: rotate(10deg);}
    25% {transform: rotate(-10deg);}
    50% {transform: rotate(20deg);}
    75% {transform: rotate(-5deg);}
    100% {transform: rotate(0deg);}
}



}