.startButton {
    background-color: #48566B; 
    border: none;

    color: #48566B;
    /* padding: 8px 10px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 135px;
    height: 45px;
    border-radius: 5px;
    /* border: 3px solid #48566B; */
    outline: none;
    font-weight: bold;
    background: linear-gradient(to right, #48566B 50%,  #c1c9d3 50%);
    background-size: 200% 100%;
    background-position: right top;
    transition: all .3s ease-out;


    /* New Change */
    
    border-radius: 0px 0px 5px  0px;
    border-bottom: 3px solid #48566B; 
    border-right: 3px solid #48566B; 
    color: #48566B;

  }


/* .startButton:hover:after {
    font-weight: bold;
    content:" ↓↓";
  } */

  .startButton:hover {
    /* background-color: #48566B;
    color: #48566B; */
    color: #c1c9d3;  
    background-position: left top;

    /* border-right-style: dashed ; */
  }




  .btnTextHomePage:hover{
    color: #48566B;
  }


  .CVButton {
    border: none;
    color: #48566B;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 30px 5%;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 50%;
    border-radius: 5px;
    border: 3px solid #c1c9d3;
    font-weight: bold;
    outline: none;
    background: linear-gradient(to left, #48566B 50%, #c1c9d3 50%);
    background-size: 200% 100%;
    background-position: left top;
    transition: all .6s ease-out;

  }

  .CVButton:hover {
    /* background-color: #48566B;
    color: #48566B; */

    color: #c1c9d3;  
    background-position: right top;
    border: 23x solid #c1c9d3;
  }


  .ContactMeSubmit{
    /* padding: 10px 20px;    */
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    margin: 0px 0px 30px ; 
    transition-duration: 0.4s;
    cursor: pointer;
    background-color: #48566B; 
    color: black; 
    border: 3px solid #c1c9d3;
    position: relative;
    width: 120px;
    height: 55px;
    font-weight: bold;
    text-align: center;
    color: #c1c9d3;
    border-radius: 5px;
    outline: none;
    background: linear-gradient(to right, #c1c9d3 50%, #48566B 50%);
    background-size: 200% 100%;
    background-position: right top;
    transition: all .5s ease-out;


    
}

.ContactMeSubmit:hover  {
    /* background-color: c1c9d3;
    color:  #48566B; */
    /* box-shadow: 0 5px 6px -2px #777; */
    color: #48566B;  
    background-position: left top;
    background-color: #c1c9d3; 
}

.ContactMeTryAgain{
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: #c1c9d3; 
  color: black; 
  border: 2px solid #48566B;
  position: relative;
  padding:3%;
  font-weight: bold;
  text-align: center;
  color: #48566B;
  border-radius: 5px;
}
.ContactMeTryAgain:hover  {
  background-color: #48566B;
  color:   #c1c9d3;
  border: 3px solid  #c1c9d3;
  /* box-shadow: 0 5px 6px -2px #777; */
}

.FiltersButton{
  background-color: #48566B; 
  color:  #c1c9d3;
  padding: 5px 5px;
  text-align: center;
  font-size: 15px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  width: 24.5%;
  height: 35px;
  border-radius: 5px;
  border: 2px solid #48566B;
}

  @media (max-width: 900px) {


}

// @media (max-device-width: 900px) {

//   .CVButton {
//     font-size: 20px;
//     width: 50%;
//   }

//   .startButton {
//     margin-top: 20px 0 20px;
//     font-size: 18px;
//     width: 120px;
//     height: 45px;
//   }

// }



// @media (max-device-width: 450px) {
//     .CVButton{
//       width: 150px;
//     }
// }